<template>
  <el-container>
    <el-header>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/home/overall">主页</el-menu-item>
        <el-menu-item index="/home/task/list">任务管理</el-menu-item>
        <el-menu-item index="/home/word-cloud">词云工具</el-menu-item>
        <el-menu-item index="/home/manual">产品手册</el-menu-item>
        <el-menu-item index="/home/user/list" v-if="showHome">用户管理</el-menu-item>
        <el-menu-item index="/home/code/list" v-if="showHome">邀请码管理</el-menu-item>
        <el-menu-item index="/home/limit/list" v-if="showHome">额度管理</el-menu-item>
        <el-menu-item index="/home/statistics" v-if="showHome">统计分析</el-menu-item>
        <el-menu-item index="/home/system-config" v-if="showHome">系统设置</el-menu-item>
        <el-submenu index="" style="float: right">
          <template slot="title"><span v-if="vip" class="vip">VIP</span> {{ name }}</template>
          <el-menu-item index="/home/user-center">用户中心</el-menu-item>
          <el-menu-item index="/home/change-password">修改密码</el-menu-item>
          <el-menu-item index="quit">退出系统</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  created() {
    let me = this;
    this.name = localStorage.getItem('name');
    let vip = localStorage.getItem("vip");
    if (vip == 1) {
      me.vip = true;
    }
    if (localStorage.getItem("role") == 99) {
      this.showHome = 1;
    }
    me.axios.get('/api/message/list').then(res => {
      if (res && res.length > 0) {
        let batchId = "msg_" + res[0]['batch_id'];
        let isRead = localStorage.getItem(batchId);
        if (!isRead) {
          let html = '<ol>';
          for (let i = 0; i < res.length; i++) {
            html += '<li>' + res[i]['message'] + '</li>';
          }
          html += '</ol>';
          me.$alert(html, '公告', {
            confirmButtonText: '我知道了', callback: function () {
              localStorage.setItem(batchId, 1);
            },
            dangerouslyUseHTMLString: true
          });
        }
      }
    });

  },
  data: function () {
    return {
      showHome: false,
      name: '',
      vip:false
    }
  },
  computed: {
    activeIndex: function () {
      let path = this.$route.path;
      if (path.startsWith("/home/task")) {
        return "/home/task/list";
      }
      return path;
    }
  },
  methods: {
    handleSelect: function (key) {
      if (key == 'quit') {
        this.quit();
      } else {
        this.$router.push(key);
      }
    },
    quit: function () {
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.vip{
  color: gold;
  font-weight: bold;
}
</style>